module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-gtag-virtual-c65d005145/0/cache/gatsby-plugin-google-gtag-npm-5.14.0-7fc489a55d-930de28fb2.zip/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-113547112-1","G-VHL3G6QG0S","AW-781608096"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-facebook-pixel-npm-1.0.8-5aef36b44a-3037256b8c.zip/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"456327274863301"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-b211812c82/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
